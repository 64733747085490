var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topics-statistics"},[_c('page-title'),_c('a-card',{attrs:{"size":"small"}},[_c('set-period',{attrs:{"disabled":_vm.topicStatisticLogic.isLoading},on:{"searchPeriod":function($event){return _vm.topicStatisticLogic.getTopicStatisticsByPeriod()}},model:{value:(_vm.topicStatisticLogic.dates),callback:function ($$v) {_vm.$set(_vm.topicStatisticLogic, "dates", $$v)},expression:"topicStatisticLogic.dates"}}),_c('div',{staticClass:"flex flex-col xs:flex-row xs:items-end gap-2"},[_c('a-button',{staticClass:"order-2 xs:order-1",attrs:{"icon":"plus"},on:{"click":function($event){_vm.isMetricsModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('statistics_add_metric_button_title'))+" ")]),_c('select-input',{staticClass:"flex-1 mb-0 pb-0 order-1 xs:order-2",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.topicStatisticLogic,
            'key': 'period',
            'prefix': 'statistic_',
            'options': _vm.periodGroupOptions,
            'clearable': false,
            'supportButtons': false
          }
        }},on:{"input":function($event){return _vm.topicStatisticLogic.getTopicStatisticsByPeriod()}}})],1)],1),_c('a-spin',{staticClass:"w-full",attrs:{"spinning":_vm.topicStatisticLogic.isLoading}},[_c('div',{staticClass:"mt-2 flex pb-2 gap-2 overflow-y-hidden"},_vm._l((_vm.topicStatisticLogic.metrics),function(metric){return _c('selected-metric-card',{key:metric,staticStyle:{"flex":"0 0 300px"},attrs:{"title":_vm.getPeriodStatMetricTitle(metric),"metric":metric},on:{"remove-metric":_vm.removeMetric}})}),1)]),_c('a-table',{staticClass:"mt-2",attrs:{"bordered":"","columns":_vm.tableColumns,"pagination":{
      'show-size-changer': false,
    },"data-source":_vm.topicStatisticLogic.statistics,"row-key":function (record) { return record.topic.id; },"loading":_vm.topicStatisticLogic.isLoading,"scroll":{ x: true }},scopedSlots:_vm._u([{key:"topic-info",fn:function(text, record){return _c('div',{},[_vm._v(" "+_vm._s(record.topic.title)+" ")])}},_vm._l((_vm.tableColumns.slice(1)),function(column){return {key:column.key,fn:function(text, record){return [(record.metrics[column.key])?[(record.metrics[column.key].series && record.metrics[column.key])?_c('vue-apex-charts',{key:column.key + '_chart',staticClass:"flex justify-center",staticStyle:{"border-bottom":"1px solid #e8e8e8"},attrs:{"type":"bar","height":"80","width":_vm.chartWidth,"series":record.metrics[column.key].series,"options":record.metrics[column.key].options}}):_vm._e()]:_vm._e()]}}})],null,true)}),_c('a-modal',{attrs:{"footer":null,"width":"90%"},model:{value:(_vm.isMetricsModalOpen),callback:function ($$v) {_vm.isMetricsModalOpen=$$v},expression:"isMetricsModalOpen"}},[_c('config-field',{attrs:{"slot":"title","title":_vm.$t('statistic_add_metric_modal_title')},slot:"title"}),_c('a-alert',{attrs:{"message":_vm.$t('statistic_add_metric_limit_message')}}),_c('default-list',{staticClass:"mt-5",attrs:{"config":{
        'data': _vm.metricsList,
        'selectFn': _vm.selectMetric,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }