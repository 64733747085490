import { TopicStatisticsMetricsEnum } from "@/includes/types/statistics/topics";
import { getMetricColorNew } from "@/includes/logic/statistics/users-analysis/utils";
import i18n from "@/i18n/i18n";
import { getPeriodStatMetricHelpMessage, getPeriodStatMetricTitle } from "@/includes/utils/statistics";

import { ListConfig } from "piramis-base-components/src/components/Pi/components/DefaultList/logic/types";

export function topicChartColors<T extends keyof typeof TopicStatisticsMetricsEnum>(metric: T) {
  switch (metric) {
    case TopicStatisticsMetricsEnum.UserCount:
      return '#9DB4C0'
    case TopicStatisticsMetricsEnum.MessageCount:
      return getMetricColorNew('MessageCount')
    case TopicStatisticsMetricsEnum.MessageReplyCount:
      return getMetricColorNew('ReplyCount')
    case TopicStatisticsMetricsEnum.Toxicity:
      return getMetricColorNew('Toxicity')
    case TopicStatisticsMetricsEnum.SevereToxicity:
      return getMetricColorNew('SevereToxicity')
    case TopicStatisticsMetricsEnum.Profanity:
      return getMetricColorNew('Profanity')
  }
}

export const topicMetricList: ListConfig['data'] = [
  {
    group: {
      guid: "1",
      titleKey: i18n.t('statistic_topics_metric_group_messages').toString(),
      groupItems: [
        {
          key: TopicStatisticsMetricsEnum.MessageCount,
          title: getPeriodStatMetricTitle(TopicStatisticsMetricsEnum.MessageCount),
          helpMessage: getPeriodStatMetricHelpMessage(TopicStatisticsMetricsEnum.MessageCount),
        },
        {
          key: TopicStatisticsMetricsEnum.MessageReplyCount,
          title: getPeriodStatMetricTitle(TopicStatisticsMetricsEnum.MessageReplyCount),
          helpMessage: getPeriodStatMetricHelpMessage("ReplyCount"), // чтобы подсказку не дублировать
        },
      ]
    }
  },
  {
    group: {
      guid: "2",
      titleKey: i18n.t('statistic_topics_metric_group_users').toString(),
      groupItems: [
        {
          key: TopicStatisticsMetricsEnum.UserCount,
          title: getPeriodStatMetricTitle(TopicStatisticsMetricsEnum.UserCount),
          helpMessage: getPeriodStatMetricHelpMessage(TopicStatisticsMetricsEnum.UserCount),
        },
      ]
    }
  },
  {
    group: {
      guid: "3",
      titleKey: i18n.t('statistic_topics_metric_group_toxicity').toString(),
      groupItems: [
        {
          key: TopicStatisticsMetricsEnum.Toxicity,
          title: getPeriodStatMetricTitle(TopicStatisticsMetricsEnum.Toxicity),
          helpMessage: getPeriodStatMetricHelpMessage(TopicStatisticsMetricsEnum.Toxicity, 'topic_'),
        },
        {
          key: TopicStatisticsMetricsEnum.SevereToxicity,
          title: getPeriodStatMetricTitle(TopicStatisticsMetricsEnum.SevereToxicity),
          helpMessage: getPeriodStatMetricHelpMessage(TopicStatisticsMetricsEnum.SevereToxicity, 'topic_'),
        },
        {
          key: TopicStatisticsMetricsEnum.Profanity,
          title: getPeriodStatMetricTitle(TopicStatisticsMetricsEnum.Profanity),
          helpMessage: getPeriodStatMetricHelpMessage(TopicStatisticsMetricsEnum.Profanity, 'topic_'),
        },
      ]
    }
  },
]
