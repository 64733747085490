import { MetricChartData } from "@/includes/types/statistics/usersAnalysis";

export type GetTopicStatisticsByPeriodRes = {
  data: Array<TopicStatisticsRecord>
}

export enum TopicStatisticsMetricsEnum {
  UserCount = "UserCount",
  MessageCount = "MessageCount",
  MessageReplyCount = "MessageReplyCount",
  Toxicity = "Toxicity",
  SevereToxicity = "SevereToxicity",
  Profanity = "Profanity",
}

export type TopicStatisticsRecordMetrics = Partial<Record<TopicStatisticsMetricsEnum, Record<string, number>>>

export type TopicStatisticsRecord = {
  id: number
  title: string
  icon: null | string
  data: TopicStatisticsRecordMetrics
}

export type TopicTableDataRecord = {
  topic: Omit<TopicStatisticsRecord, 'data'>
  metrics: Partial<Record<TopicStatisticsMetricsEnum, MetricChartData>>
}
