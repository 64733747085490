import {
  GetTopicStatisticsByPeriodRes,
  TopicStatisticsMetricsEnum, TopicStatisticsRecord, TopicStatisticsRecordMetrics,
  TopicTableDataRecord
} from "@/includes/types/statistics/topics";
import { topicChartColors } from "@/includes/logic/statistics/topics";
import {
  convertLabels, getMaxValuesForEachMetric,
  getPeriodStatMetricTitle,
  tickUnitByPeriod,
  updateDateToStart
} from "@/includes/utils/statistics";
import { errorNotification } from "@/includes/NotificationService";
import StatisticsApi from "@/includes/Api/Statistics.api";
import store from "@/store/store";
import { constArrayIncludes } from "@/includes/types/util.types";

import { fromToTicks, FromToTicksReturnType } from "piramis-base-components/src/shared/utils/fromToTicks";

import { ApexOptions } from "apexcharts";
import moment from "moment";
import { StatisticPeriodEnum } from "@/includes/types/statistics/statistics.types";

export class TopicStatisticLogic {
  TOXICITY_METRICS = [
    TopicStatisticsMetricsEnum.Toxicity,
    TopicStatisticsMetricsEnum.SevereToxicity,
    TopicStatisticsMetricsEnum.Profanity ] as const

  dates = {
    from: moment().add(-1, 'month').format('YYYY-MM-DD'),
    to:  moment().format('YYYY-MM-DD')
  }

  metrics: Array<TopicStatisticsMetricsEnum> = [ TopicStatisticsMetricsEnum.UserCount, TopicStatisticsMetricsEnum.MessageReplyCount,  ]

  statistics: Array<TopicTableDataRecord> | null = null

  isLoading = false

  ticks: FromToTicksReturnType | null = null

  maxValues: Record<string, number> | null = null

  period: StatisticPeriodEnum = StatisticPeriodEnum.Day

  removeMetric(metric: TopicStatisticsMetricsEnum) {
    if (this.metrics.length > 1) {
      this.metrics = this.metrics.filter(m => m !== metric)
    }
  }

  async getTopicStatisticsByPeriod() {
    this.isLoading = true

    try {
      const { data } = await StatisticsApi.getTopicStatisticsByPeriod({
        chat_id: store.getters.chatId,
        from: this.dates.from,
        to: this.dates.to,
        metrics: this.metrics,
        period: this.period
      })

      await this.setMaxMetricsValues(data.data)

      this.updateTicks()

      this.statistics = await this.prepareTopics(data.data, this.ticks!)
    } catch (error) {
      errorNotification(error)
    } finally {
      this.isLoading = false
    }
  }

  updateTicks() {
    this.ticks = this.getFromToTicks()
  }

  getFromToTicks() {
    const { from, to } = updateDateToStart(this.dates, this.period)

    return fromToTicks(from, to, { unit: tickUnitByPeriod(this.period) })
  }

  generateColumnOptions(metric: TopicStatisticsMetricsEnum, maxValue: number | undefined): ApexOptions {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
        redrawOnParentResize: false
      },
      colors: [ topicChartColors(metric) ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding: {
          right: 0,
          left: 0,
          bottom: 0,
          top: 0
        }
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: convertLabels(this.ticks!.timeTicks),
      },
      yaxis: {
        min: 0,
        max: maxValue,
        show: false
      },
      tooltip: {
        marker: {
          show: false
        },
        y: {
          formatter: (val: number, opts?: any): string => {
            if (constArrayIncludes(this.TOXICITY_METRICS, metric)) {
              return val.toFixed(2) + '%'
            }

            return val.toFixed(0)
          },
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }

  async setMaxMetricsValues(data: GetTopicStatisticsByPeriodRes['data']) {
    this.maxValues = await getMaxValuesForEachMetric(data)
  }

  async getMetricData(statistics: TopicStatisticsRecordMetrics, metric: TopicStatisticsMetricsEnum, ticks: FromToTicksReturnType) {
    const { mapTimeData } = ticks;

    const metricData = statistics[metric];
    const title = getPeriodStatMetricTitle(metric)
    const metricOptions = this.generateColumnOptions(metric, this.maxValues?.[metric]);

    return new Promise<TopicTableDataRecord['metrics']>(resolve => {
      if (metricData) {
        resolve({
          [metric]: {
            title,
            metric,
            options: metricOptions,
            series:[ { name: title, data: Object.values(Object.assign({ ...mapTimeData }, metricData)) } ]
          },
        })
      } else {
        resolve({
          [metric]: {
            title,
            metric,
            options: metricOptions,
            series: [ { name: title, data: Object.values(mapTimeData) } ],
          },
        })
      }
    })
  }

  async prepareTopic(record: TopicStatisticsRecord, ticks: FromToTicksReturnType): Promise<TopicTableDataRecord> {
    const { data, ...topicInfo } = record;

    const metrics: TopicTableDataRecord['metrics'] = {};

    for (const metric of this.metrics) {
      const result = await this.getMetricData(data, metric, ticks);

      Object.assign(metrics, result);
    }

    return {
      topic: topicInfo,
      metrics,
    };
  }

  async prepareTopics(data: GetTopicStatisticsByPeriodRes['data'], ticks: FromToTicksReturnType) {
    return await Promise.all(
        data.map(async (record) => await this.prepareTopic(record, ticks))
    );
  }
}
