





















































































































import SetPeriod from "@/views/chat/statistics/components/mainStatistics/setPeriod.vue";
import { TopicStatisticsMetricsEnum, } from "@/includes/types/statistics/topics";
import { ClassProperties, isEnumValue } from "@/includes/types/util.types";
import { getPeriodStatMetricTitle } from "@/includes/utils/statistics";
import SelectedMetricCard from "@/components/statistic/components/SelectedMetricCard.vue";
import { warningNotification } from "@/includes/NotificationService";
import { TopicStatisticLogic, topicMetricList } from "@/includes/logic/statistics/topics";

import { Item } from "piramis-base-components/src/components/Pi/components/DefaultList/logic/types";
import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue';
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue';
import { SelectOption } from "piramis-base-components/src/logic/types";
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue';

import { Component, Mixins } from 'vue-property-decorator';
import VueApexCharts from "vue-apexcharts";
import { Column } from "ant-design-vue/types/table/column";
import { StatisticPeriodEnum } from "@/includes/types/statistics/statistics.types";

@Component({
  methods: {
    getPeriodStatMetricTitle,
  },
  components: {
    SelectedMetricCard,
    SetPeriod,
    VueApexCharts,
    DefaultList,
    ConfigField,
    SelectInput,
    PageTitle
  }
})
export default class StatisticsTopics extends Mixins(ModelSetter) {
  topicStatisticLogic = new TopicStatisticLogic()

  isMetricsModalOpen = false

  get metricsList() {
    return topicMetricList
  }

  get chartWidth() {
    if (this.$screen.xl) {
      return '300px'
    }

    return '250px'
  }

  get periodGroupOptions():Array<SelectOption> {
    return [
      {
        label: this.$t(`statistics_group_day`).toString(),
        value: StatisticPeriodEnum.Day
      },
      {
        label: this.$t(`statistics_group_week`).toString(),
        value: StatisticPeriodEnum.Week
      },
      {
        label: this.$t(`statistics_group_month`).toString(),
        value: StatisticPeriodEnum.Month
      },
      {
        label: this.$t(`statistics_group_year`).toString(),
        value: StatisticPeriodEnum.Year
      },
    ]
  }

  get tableColumns() {
    const baseColumn = {
      title: this.$t('statistic_topics_table_topic_info_column').toString(),
      key: 'topic-info',
      width: 180,
      scopedSlots: { customRender: 'topic-info' },
    }

    return [
      baseColumn,
      ...this.topicStatisticLogic.metrics.map<ClassProperties<Partial<Column>>>(metric => ({
        title: getPeriodStatMetricTitle(metric),
        key: metric,
        width: 250,
        scopedSlots: { customRender: metric },
      }))
    ]
  }

  removeMetric(metric: TopicStatisticsMetricsEnum) {
    this.topicStatisticLogic.removeMetric(metric)

    this.topicStatisticLogic.getTopicStatisticsByPeriod()
  }

  selectMetric(item: Item) {
    const { key } = item

    if(isEnumValue(TopicStatisticsMetricsEnum, key)) {
      if (this.topicStatisticLogic.metrics.includes(key)) {
        warningNotification(this.$t('statistic_add_metric_existing_warning_notification').toString())
        return
      }
      else if (this.topicStatisticLogic.metrics.length === 5) {
        warningNotification(this.$t('statistic_add_metric_limit_message').toString())
        return
      }

      this.topicStatisticLogic.metrics.push(key)

      this.isMetricsModalOpen = false

      this.$nextTick(() => {
        this.topicStatisticLogic.getTopicStatisticsByPeriod()
      })
    }
  }

  created() {
    if (this.$store.getters.isChatForum) {
      this.topicStatisticLogic.getTopicStatisticsByPeriod()
    } else {
      this.$router.replace({ name: 'Community_dashboard' })
    }
  }
}
